import React from "react"
import styled from "styled-components"
import Layout from "components/Layout/Layout"
import MaxContainer from "components/shared/MaxContainer"
import ImagesWithText from "components/shared/ImagesWithText"
import SEO from "utils/Seo"
import { media } from "utils/Media"

import image1 from "images/home/5.jpg"
import image2 from "images/home/7.jpg"

const Cost = styled.p`
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;

    span {
        width: 100%;
        display: block;
        @media ${media.md} {
            width: 40%;
            float: right;
        }
    }
`

const CostsPage = () => (
    <Layout>
        <SEO title="Costs | Garden Designer | Landscape Gardener Devon | Paving Companies" description="The costs will depend entirely on your requirements and needs which will form the basis for the brief and what already exists in the space. You can find out more about estimated costs here on this page."/>
        <MaxContainer>
            <ImagesWithText
                image1={{ src: image1, alt: "landscape gardening" }}
                image2={{ src: image2, alt: "landscape gardening" }}
            >
                <h1>A Guide to Budgets</h1>
                <p>The costs will depend entirely on your requirements and needs which will form the basis for the brief (See design process) and also what already exists in the space.</p>
                <p>Hard landscaping such as walls, ponds, and patios are a lot more expensive per m2 than lawns and gravel.</p>
                <p>A large lawn and small patio will be a fraction of the cost of a large patio terrace and a small lawn, within a similar sized space.</p>
                <p>A sloping garden may need retaining structures (walls) to create level areas; this will require a higher budget than say a level garden of a similar size, 
                although a level garden may require structures to create vertical interest or define space through level changes.</p>
                <p>Below is a guide to design fees.</p>

                <br />

                <Cost>Small urban estate size garden <span>£5000 - £8000 | Design Fee - £640</span></Cost>

                <Cost>Average 3 bed family home <span>£12000 - £20000 | Design Fee - £800</span></Cost>

                <Cost>4 bed home or barn conversion larger garden <span>£20000 - £30000 | Design Fee - £960</span></Cost>

                <Cost>Large garden up to half an acre <span>£32000 - £54000 | Design Fee - £1280</span></Cost>

                <br />
                <p>Smaller and Larger projects can be quoted for.</p>
                <p>Free initial consultation.</p>
            </ImagesWithText>
        </MaxContainer>
    </Layout>
)

export default CostsPage
