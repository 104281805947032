import React, { Component } from "react"
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { media } from 'utils/Media'

const BorderedWrap = styled.div`
    border: 1px solid black;
    border-top: 0;
    padding: 30px 15px;
    height: 100%;
    margin-bottom: 2rem;

    @media ${media.md} {
        border-top: 1px solid black;
        padding: 30px;
        margin-bottom: 0;
    }
`

class ImagesWithText extends Component {

    render() {

        return(
            <Row>
                <Col md={8}>
                    <BorderedWrap>
                        {this.props.children}
                    </BorderedWrap>
                </Col>
                <Col md={4}>
                    <img src={this.props.image1.src} alt={this.props.image1.alt} className="pb-4" />
                    <img src={this.props.image2.src} alt={this.props.image2.alt} />
                </Col>
            </Row>
        )
    }
}

export default ImagesWithText